import Storage from "@/modules/storage";

const validateFormData = (input: any) => {
  let value = input.value;
  const type = input.type;
  const required = input.required;
  const min = input.min;
  const max = input.max;
  const minlength = input.min;
  const maxlength = input.max;

  if (required && value === "") {
    return false;
  } else if (minlength && value.length < minlength) {
    return false;
  } else if (minlength && value.length > maxlength) {
    return false;
  }
  if (type === "number") {
    value = parseInt(value);
    if (value > max || value < min) {
      return false;
    }
  }

  return true;
};

const validatePassword = (rawPassword: string) => {
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const specialLetters = "ñÑ~!@#$%^&*+-/.,{}[]();: ";
  const digits = "0123456789";

  let upper = 0;
  let lower = 0;
  let digit = 0;
  let special = 0;

  if (rawPassword.length > 50) {
    return [false, "over"];
  } else if (rawPassword.length < 8) {
    return [false, "under"];
  }

  for (let i = 0; i < rawPassword.length; i++) {
    if (lowercase.includes(rawPassword.charAt(i))) {
      lower++;
    } else if (uppercase.includes(rawPassword.charAt(i))) {
      upper++;
    } else if (specialLetters.includes(rawPassword.charAt(i))) {
      special++;
    } else if (digits.includes(rawPassword.charAt(i))) {
      digit++;
    } else {
      return [false, "not valid", rawPassword.charAt(i)];
    }
  }

  if (upper === 0) {
    return [false, "upper"];
  } else if (lower === 0) {
    return [false, "lower"];
  } else if (digit === 0) {
    return [false, "digit"];
  } else if (special === 0) {
    return [false, "special", specialLetters];
  }

  return [true, rawPassword];
};

const prettyDate = (timestamp: number) => {
  return new Promise((resolve, reject) => {
    if (!timestamp) {
      resolve(0);
    }
    const store = new Storage();
    store.getData("country", undefined).then((countryArray: any) => {
      countryArray.forEach((country: any) => {
        if (country._id == localStorage.getItem("user.country")) {
          const format =
            country.languageCode.toLowerCase() +
            "-" +
            country.ISOCode.toUpperCase();
          const newDate = new Date(timestamp);
          const dateFormater = new Intl.DateTimeFormat(format).format(newDate);
          resolve(dateFormater);
        }
      });
    });
  });
};

const prettyID = (ID: number) => {
  return new Promise((resolve, reject) => {
    const i = ID.toString();
    const j = i.length > 3 ? i.length % 3 : 0;
    resolve(
      (j ? i.substr(0, j) + "." : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + ".")
    );
  });
};

const titleCase = (text: string) => {
  return text.replace(/\w\S*/g, function(txt) {
    let prev: any = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    prev = prev.split("_");
    return prev.join(" ");
  });
};

export { validateFormData, validatePassword, prettyDate, prettyID, titleCase };
