<template>
  <div class="mainTable px-4 py-3 rounded card">
    <h5 class="fw-bold">{{ tableTitle }}</h5>
    <div class="tableInfo d-flex justify-content-between mt-3">
      <nav>
        <ul class="pagination">
          <li class="page-item">
            <a
              class="previousPage page-link"
              href="#"
              @click.prevent="movePage('previous')"
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          <li
            v-for="index in [...Array(pagCount).keys()]"
            :key="index"
            class="page-item"
            v-bind:class="{ active: index + 1 == currentPage }"
          >
            <a
              class="page-link"
              href="#"
              @click.prevent="movePage(index + 1)"
              >{{ index + 1 }}</a
            >
          </li>
          <li class="page-item">
            <a
              class="nextPage page-link"
              href="#"
              @click.prevent="movePage('next')"
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
        </ul>
      </nav>
      <div class="tableSearch d-flex">
        <form class="d-flex" @submit.prevent="null">
          <input
            class="form-control"
            type="search"
            placeholder="Buscar"
            aria-label="Buscar"
            v-model="searchTable"
            @keyup="resetPageCount"
          />
          <svg class="bi" fill="currentColor">
            <use xlink:href="/img/icons/bootstrap-icons.svg#search" />
          </svg>
        </form>
        <router-link
          :to="actionButtonLink"
          class="ms-3"
          v-if="disableMainButton != 'true'"
          ><button type="button" class="btn btn-primary shadow-primary">
            {{ actionButtonName }}
          </button></router-link
        >
        <button
          type="button"
          class="ms-3 btn btn-primary shadow-primary"
          disabled
          v-else
        >
          {{ actionButtonName }}
        </button>
      </div>
    </div>
    <div class="tableContainer">
      <table class="table">
        <thead>
          <tr>
            <th v-for="header in headers" :key="header" scope="col">
              {{ header }}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="rowTable" v-for="row in computedData" :key="row">
            <th class="tableFirstColumn" scope="row">{{ row.indexTable }}</th>
            <td v-for="info in row.data" :key="info">
              <span>{{ info }}</span>
            </td>
            <td class="text-end">
              <div class="dropdown">
                <svg
                  @click="activeOptions = row.indexTable"
                  fill="currentColor"
                  class="bi dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="true"
                >
                  <use
                    xlink:href="/img/icons/bootstrap-icons.svg#three-dots-vertical"
                  />
                </svg>

                <ul
                  class="dropdown-menu menuOffset"
                  :class="{ show: activeOptions === row.indexTable }"
                  @mouseleave="hideAllOptions"
                >
                  <li
                    v-if="editOption"
                    class="dropdown-item editOption"
                    @click="$emit('editAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#pencil-fill"
                      />
                    </svg>
                    Editar
                  </li>
                  <li
                    class="dropdown-item printOption"
                    v-if="printOption"
                    @click="$emit('printAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#printer-fill"
                      />
                    </svg>
                    Imprimir
                  </li>
                  <li
                    class="dropdown-item downloadOption"
                    v-if="downloadOption"
                    @click="$emit('downloadAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#cloud-arrow-down-fill"
                      />
                    </svg>
                    Descargar
                  </li>
                  <li
                    class="dropdown-item executeOption"
                    v-if="executeOption"
                    @click="$emit('executeAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#check-circle-fill"
                      />
                    </svg>
                    Ejecutar
                  </li>
                  <li
                    class="dropdown-item minutesOption"
                    v-if="minutesOption"
                    @click="$emit('minutesAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#file-earmark-check-fill"
                      />
                    </svg>
                    Reporte de Ejecución
                  </li>
                  <li
                    class="dropdown-item transferOption"
                    v-if="transferOption"
                    @click="$emit('transferAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#arrow-up-right-square-fill"
                      />
                    </svg>
                    Trasnferir
                  </li>
                  <li
                    class="dropdown-item deleteOption"
                    v-if="deleteOption"
                    @click="$emit('deleteAction', row.data[0])"
                  >
                    <svg fill="currentColor" class="bi">
                      <use
                        xlink:href="/img/icons/bootstrap-icons.svg#x-square-fill"
                      />
                    </svg>
                    Eliminar
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      class="blackBackground"
      v-if="activeOptions"
      @click="hideAllOptions"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: [
    "tableTitle",
    "actionButtonName",
    "actionButtonLink",
    "headers",
    "data",
    "editOption",
    "transferOption",
    "deleteOption",
    "printOption",
    "downloadOption",
    "minutesOption",
    "executeOption",
    "disableMainButton"
  ],
  data() {
    return {
      rowLimits: 20,
      currentPage: 1,
      searchTable: "",
      activeOptions: false
    };
  },
  methods: {
    movePage(move) {
      if (move == "previous" && this.currentPage > 1) {
        this.currentPage--;
      } else if (move == "next" && this.currentPage < this.pagCount) {
        this.currentPage++;
      } else if (Number.isInteger(move)) {
        this.currentPage = move;
      }
    },
    resetPageCount() {
      this.currentPage = 1;
    },
    hideAllOptions() {
      this.activeOptions = false;
    }
  },
  computed: {
    pagCount: function() {
      return Math.ceil(this.postDataLength / this.rowLimits);
    },
    postDataLength: function() {
      let length = 0;
      let searchCheck;
      if (this.searchTable === "") {
        length = this.data.length;
      } else {
        this.data.forEach(element => {
          searchCheck = this.searchTable.toUpperCase();
          searchCheck = element
            .join("")
            .toUpperCase()
            .indexOf(searchCheck);
          if (searchCheck > -1) {
            length++;
          }
        });
      }
      return length;
    },
    computedData: function() {
      const postData = [];
      const min = (this.currentPage - 1) * this.rowLimits + 1;
      const max = this.currentPage * this.rowLimits;
      let newData, searchCheck;
      let count = 0;
      this.data.forEach((element, index) => {
        newData = {
          indexTable: index + 1,
          data: element
        };
        searchCheck = this.searchTable.toUpperCase();
        searchCheck = element
          .join("")
          .toUpperCase()
          .indexOf(searchCheck);
        if (searchCheck > -1) {
          count++;
          if (count >= min && count <= max) {
            postData.push(newData);
          }
        }
      });
      return postData;
    }
  }
};
</script>

<style lang="css" scoped>
.mainTable {
  background-color: var(--secondary-bg);
}
.mainTable a {
  background-color: var(--main-bg);
  color: var(--main-text);
  border: none;
}
.previousPage {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.nextPage {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.mainTable input {
  background-color: var(--main-bg);
  height: 33px;
  padding: 0;
  text-indent: 30px;
}
.mainTable form > svg {
  position: absolute;
  width: 16px;
  height: 33px;
  margin-left: 7px;
}
.mainTable button {
  height: 33px;
}
.tableContainer {
  width: 100%;
}
.mainTable table svg {
  cursor: pointer;
  height: 20px;
  width: 20px;
}
.tableSearch a {
  background-color: var(--secondary-bg);
  height: 33px;
}
.rowTable {
  transition: 0.3s ease-in-out;
}
.rowTable:hover {
  background-color: var(--main-bg);
}
.rowTable td:nth-child(2) {
  display: none;
}
.dropdown-menu li:hover {
  background-color: var(--main-bg);
  cursor: pointer;
  text-decoration: underline;
}
.dropdown-menu li svg {
  width: 16px;
  margin-right: 5px;
}
.editOption {
  color: gray;
}
.transferOption {
  color: var(--bs-orange);
}
.deleteOption {
  color: var(--bs-danger);
}
.printOption {
  color: var(--bs-yellow);
}
.downloadOption {
  color: var(--bs-blue);
}
.minutesOption {
  color: var(--bs-gray-dark);
}
.executeOption {
  color: var(--bs-primary);
}
.blackBackground {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 11;
}
.menuOffset {
  left: -80px !important;
}
@media only screen and (max-width: 576px) {
  .mainTable h5 {
    text-align: center;
  }
  .tableInfo {
    flex-direction: column;
  }
  .tableInfo ul {
    justify-content: center;
  }
  .tableSearch {
    flex-direction: column;
  }
  .tableSearch a {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 10px;
  }
  .tableSearch button {
    width: 100%;
  }
  .tableContainer {
    overflow-x: auto;
    margin-top: 10px;
  }
  .dropdown-menu {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .tableFirstColumn {
    position: absolute;
    background-color: var(--secondary-bg);
    border: none;
  }
  .blackBackground {
    background-color: rgba(0, 0, 0, 0.74);
  }
  .menuOffset {
    left: 50% !important;
  }
}
</style>
