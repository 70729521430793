<template>
  <div class="main">
    <TableMain
      tableTitle="Tabla de Retención Documental"
      class="mx-4 mt-4 mb-5"
      actionButtonName="Crear Documento"
      :actionButtonLink="'create/' + $route.params._id"
      :headers="['#', 'Nombre', 'Versión', 'Responsable', 'Vencimiento']"
      :data="files"
      :editOption="true"
      :deleteOption="true"
      :downloadOption="true"
      @editAction="editEvent($event)"
      @deleteAction="deleteEvent($event)"
      @downloadAction="downloadEvent($event)"
    >
    </TableMain>
    <Legal></Legal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";
import Legal from "@/components/layout/Legal";
import TableMain from "@/components/layout/Table";

import { prettyDate } from "@/modules/tools";

export default {
  components: { Legal, TableMain },
  beforeCreate() {
    const store = new Storage();
    store.getData("branch").then(branchData => {
      branchData.forEach(branch => {
        if (this.$route.params._id === branch._id) {
          store.getData("city", branch.city).then(cityData => {
            localStorage.setItem(
              "currentLocation",
              "Documentos / Retención Documental / " + cityData.name
            );
          });
        }
      });
    });
  },
  methods: {
    editEvent(_id) {
      const store = new Storage();
      store.getData("file", _id).then(file => {
        this.$router.push({
          name: "Document Main Branch File Edit",
          params: {
            _id: file._id,
            fileId: file.parent
          }
        });
      });
    },
    deleteEvent(_id) {
      const store = new Storage();
      store.getData("file", _id).then(file => {
        Swal.fire({
          title: "¿Eliminar Documento?",
          text: "Se eliminará el documento: " + file.name,
          showCancelButton: true,
          confirmButtonText: `Eliminar`,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#dc3545",
          cancelButtonColor: "#6c757d"
        }).then(result => {
          if (result.isConfirmed) {
            Swal.fire({
              icon: "warning",
              title: "Eliminando documento",
              html:
                "Por favor espere mientras se elimina el documento</b> " +
                '<div class="progress mt-3"><div class="progress-bar" id="progressBranch" role="progressbar" style="width: 0%;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div></div>',
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              stopKeydownPropagation: true
            });
            store.deleteData("optima", "file", _id).then(() => {
              Swal.fire({
                icon: "success",
                title: "Documento Eliminado",
                text: "Todos los datos han sido sincronizados"
              }).then(() => {
                this.$router.go();
              });
            });
          }
        });
      });
    },
    downloadEvent(_id) {
      const store = new Storage();
      store.getData("file", _id).then(fileData => {
        const URL =
          store.baseURL + "/optima/file/download?where=_id=" + fileData._id;
        axios.get(URL, { withCredentials: true }).then(res => {
          window.open(res.data.body.URL);
        });
      });
    },
    getEmployee(employeeID, position) {
      this.employees.forEach(employee => {
        if (employee._id === employeeID) {
          this.files[position][3] = employee.name;
        }
      });
    }
  },
  created() {
    const store = new Storage();
    let aux = 0;
    store.getData("employee").then(employees => {
      this.employees = employees;
      store.getData("file").then(file => {
        file.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
        file.forEach(document => {
          if (
            document.branch === this.$route.params._id &&
            document.responsable
          ) {
            prettyDate(document.expiration).then(expiration => {
              this.files.push([
                document._id,
                document.name,
                document.version,
                null,
                expiration
              ]);
              this.getEmployee(document.responsable, aux);
              aux++;
            });
          }
        });
      });
    });
  },
  data() {
    return {
      files: [],
      employees: []
    };
  },
  name: "Document Retention Branch"
};
</script>
